import styled from 'styled-components/macro';

const IconButton = styled.button`
    position: relative;

    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;

    border: none;
    border-radius: 0;

    color: inherit;
    vertical-align: middle;

    background: transparent;
    outline: none;
    outline: 0;
    cursor: pointer;

    appearance: none;
    user-select: none;

    &:hover {
        color: rgba(0, 0, 0, 0.8);
    }

    &:focus {
        color: rgba(0, 0, 0, 0.6);
    }
`;

export default IconButton;
