import styled from 'styled-components/macro';
import { sm } from 'constants/device-widths';

export const StyledBricksHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 1rem 1.5rem 0 2.5rem;

    @media (max-width: ${sm}px) {
        & {
            z-index: 300;

            flex-direction: column;
            align-items: stretch;
            width: 100%;
            padding: 0;
        }
    }
`;

// noinspection CssUnknownProperty
export const StyledHeaderInput = styled.textarea`
    flex: 1 1 auto;
    padding: 0;

    border: none;

    color: rgba(0, 0, 0, 0.6);
    font-weight: 500;

    outline: none;

    resize: none;
    scrollbar-width: none;

    &::placeholder {
        color: rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const StyledToolbar = styled.div`
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 1.5em;

    color: rgba(0, 0, 0, 0.4);
    font-size: 0.875rem;

    & > * {
        margin-left: 0.25rem;
    }

    & > *:last-child {
        font-size: 1.4em;
    }

    @media (max-width: ${sm}px) {
        display: ${({ mobile }) => (mobile ? 'flex' : 'none')};

        font-size: 1.2rem;

        & > * {
            width: 3rem;
            height: 3rem;
        }

        & > *:last-child {
            margin-right: 0.25rem;
        }
    }
`;

export const GoToNavigationWrapper = styled.div`
    display: none;

    background-color: white;

    @media (max-width: ${sm}px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
`;

export const TextAreaWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;

    font-size: var(--title-font-size);

    @media (max-width: ${sm}px) {
        margin-left: 2.5rem;
        padding-right: 1.5rem;
    }
`;

export const GoToNavigation = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    margin: 2px;
    padding: 0.5rem 0.5rem 0.5rem 0;

    border: none;

    color: var(--toolbar-color-activ);
    font-size: var(--toolbar-font-size);

    background: transparent;
    cursor: pointer;

    & > svg {
        font-size: 1.4em;
    }

    & > span {
        padding-right: 0.25rem;
    }
`;
