import LZString from 'lz-string';

const TITLE_PARAM_NAME = 't';
const DOC_PARAM_NAME = 'd';

export const hasSharedNoteParams = params => {
    params = params || new URLSearchParams(window.location.search);
    return params.has(TITLE_PARAM_NAME) && params.has(DOC_PARAM_NAME);
};

export const clearParams = () => {
    const { location, history } = window;
    const url = new URL(location);
    url.search = '';
    history.replaceState({}, '', url);
};

export const read = () => {
    const params = new URLSearchParams(window.location.search);

    if (!hasSharedNoteParams(params)) {
        return {};
    }

    try {
        const parsed = JSON.parse(LZString.decompressFromEncodedURIComponent(params.get(DOC_PARAM_NAME)));
        return { doc: parsed, title: params.get(TITLE_PARAM_NAME) };
    } catch (e) {
        return {};
    }
};

export const buildShareUrl = ({ doc, title }) => {
    const hash = LZString.compressToEncodedURIComponent(JSON.stringify(doc));
    const { location } = window;
    const url = new URL(location);
    const params = new URLSearchParams();
    params.set(TITLE_PARAM_NAME, title);
    params.set(DOC_PARAM_NAME, hash);
    url.search = params;
    return url.href;
};
