import { parseNumber, precise } from 'math/number-literal-utils';
import { NumberType } from 'math/calculate/number-type';
import { PERCENT_TYPE } from 'math/calculate/calculation-types';
import { calcTypeExp } from './type-utils';

class PercentType {
    constructor(value) {
        this.type = PERCENT_TYPE;
        this.value = value;
    }

    plus(other) {
        return calcTypeExp((l, r) => new PercentType(l + r)).onlyIfOtherIsPercent(this, other);
    }

    rplus(other) {
        return calcTypeExp((l, r) => new NumberType((l * r) / 100 + r)).onlyIfOtherIsNumber(this, other);
    }

    minus(other) {
        return calcTypeExp((l, r) => new PercentType(l - r)).onlyIfOtherIsPercent(this, other);
    }

    rminus(other) {
        return calcTypeExp((l, r) => new NumberType(r - (l * r) / 100)).onlyIfOtherIsNumber(this, other);
    }

    multiply(other) {
        return (
            calcTypeExp((l, r) => new NumberType(precise((l / 100) * r))).onlyIfOtherIsNumber(this, other) ||
            calcTypeExp((l, r) => new NumberType(precise((l / 100) * (r / 100)))).onlyIfOtherIsPercent(this, other)
        );
    }

    rmultiply(other) {
        return calcTypeExp((l, r) => new NumberType(precise((l / 100) * r))).onlyIfOtherIsNumber(this, other);
    }

    of(other) {
        return this.multiply(other);
    }

    risof(other) {
        return calcTypeExp((l, r) => new NumberType(r / (l / 100))).onlyIfOtherIsNumber(this, other);
    }

    toString() {
        return this.value + '%';
    }
}

export const createPercent = val => {
    return new PercentType(parseNumber(val.slice(0, val.length - 1)));
};
