import styled from 'styled-components/macro';
import { md, sm } from 'constants/device-widths';
import { GUTTER_ZI, NOTEPAD_ZI } from 'constants/z-indexes';

const StyledBricks = styled.div`
    position: relative;

    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    box-sizing: border-box;

    background-color: var(--editor-bg-color);

    @media (max-width: ${md}px) {
        & {
            width: calc(100% - 220px);
        }
    }

    @media (max-width: ${sm}px) {
        & {
            width: 100%;
        }
    }
`;

export const NotepadContainer = styled.div`
    z-index: ${NOTEPAD_ZI};

    box-sizing: border-box;
    margin: 0.25rem 0.25rem 0.25rem 0;
    padding-left: 1.5rem;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 4px;

        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        width: 4px;

        border-radius: 8px;

        background: rgba(196, 196, 196, 0.5);
    }
`;

// noinspection ALL
export const Gutter = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: ${GUTTER_ZI};

    box-sizing: border-box;
    width: 24px;

    border-left: 1px solid rgba(0, 0, 0, 0.12);

    background-color: var(--editor-gutter-bg-color);

    @media (max-width: ${sm}px) {
        & {
            border: none;
        }
    }

    /* stylelint-disable*/
    @supports (-webkit-touch-callout: none) {
        @media (max-width: ${md}px) {
            bottom: unset;

            height: -webkit-fill-available;
        }
    }
`;

export default StyledBricks;
