import { useEffect, useRef } from 'react';
import { clearParams, hasSharedNoteParams, read } from 'utils/hash-utils';

export const useSharedNotesReader = (inited, handler) => {
    const handlerRef = useRef(() => {});

    useEffect(() => {
        handlerRef.current = handler;
    });

    useEffect(() => {
        if (!inited || !hasSharedNoteParams()) return;

        handlerRef.current(read());

        clearParams();
    }, [inited]);
};
