import { nanoid } from 'nanoid';

export const uniqueTabId = nanoid();

/**
 * add `creating`, `updating`, `deleting` hooks which setup source field for transaction,
 * that field is used in Observable plugin
 */
export const addSourceToTransaction = table => {
    table.hook('creating', (primKey, obj, trans) => {
        trans.source = uniqueTabId;
    });
    table.hook('updating', (primKey, obj, trans) => {
        trans.source = uniqueTabId;
    });
    table.hook('deleting', (primKey, obj, trans) => {
        trans.source = uniqueTabId;
    });
};
