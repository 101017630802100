import { createToken, Lexer } from 'chevrotain';

export const AdditionOperator = createToken({ name: 'AdditionOperator', pattern: Lexer.NA });
export const Plus = createToken({ name: 'Plus', pattern: /\+/, categories: AdditionOperator });
export const Minus = createToken({ name: 'Minus', pattern: /-/, categories: AdditionOperator });

export const MultiplicationOperator = createToken({ name: 'MultiplicationOperator', pattern: Lexer.NA });
export const Multi = createToken({ name: 'Multi', pattern: /\*/, categories: MultiplicationOperator });
export const Div = createToken({ name: 'Div', pattern: /\//, categories: MultiplicationOperator });

export const PowerOperator = createToken({ name: 'PowerOperator', pattern: Lexer.NA });
export const Power = createToken({ name: 'Power', pattern: /\^/, categories: PowerOperator });

export const LParen = createToken({ name: 'LParen', pattern: /\(/ });
export const RParen = createToken({ name: 'RParen', pattern: /\)/ });
export const NumberLiteral = createToken({ name: 'NumberLiteral', pattern: /\d(( )?\d)*([.,]\d+)?([km])?/i });
export const PercentLiteral = createToken({ name: 'PercentLiteral', pattern: /\d(( )?\d)*([.,]\d+)?\s*(%)/i });

//expressions
export const OfExpression = createToken({ name: 'OfExpression', pattern: Lexer.NA });
export const OfToken = createToken({ name: 'OfToken', pattern: /(of)/, categories: OfExpression });
export const IsToken = createToken({ name: 'IsToken', pattern: /(is)/, categories: OfExpression });

// marking WhiteSpace as 'SKIPPED' makes the lexer skip it.
export const WhiteSpace = createToken({
    name: 'WhiteSpace',
    pattern: /\s+/,
    group: Lexer.SKIPPED
});

export const allTokens = [
    WhiteSpace, // whitespace is normally very common so it should be placed first to speed up the lexer's performance
    Plus,
    Minus,
    Multi,
    Div,
    LParen,
    RParen,
    PowerOperator,
    Power,
    PercentLiteral,
    NumberLiteral,
    AdditionOperator,
    MultiplicationOperator,
    OfExpression,
    OfToken,
    IsToken
];

export const MathLexer = new Lexer(allTokens);
