import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    GoToNavigation,
    GoToNavigationWrapper,
    StyledBricksHeader,
    StyledHeaderInput,
    StyledToolbar,
    TextAreaWrapper
} from 'components/bricks-header/StyledBricksHeader';
import ShareIcon from 'components/svg-icons/ShareIcon';
import { share, focus } from 'emitters/app-emitter';
import throttle from 'lodash/throttle';
import { subscribe } from 'emitters/file-title-emitter';
import IconButton from 'components/svg-icons/IconButton';
import FileMenuButton from 'components/file-tree/FileMenuButton';
import { showSnack } from 'emitters/snack-emitter';
import ChevronLeft from 'components/svg-icons/ChevronLeft';

const fixTextareaHeight = textarea => {
    textarea.style.height = 0;
    textarea.style.height = textarea.scrollHeight + 'px';
};

const AutoHeightTextArea = props => {
    const textareaEl = useRef();

    useEffect(() => {
        fixTextareaHeight(textareaEl.current);
    }, []);

    const handleInput = useCallback(() => {
        fixTextareaHeight(textareaEl.current);
    }, []);

    const handleEnter = useCallback(event => {
        const enterIsPressed = event.key === 'Enter';
        if (enterIsPressed) {
            event.preventDefault();
            focus();
        }
    }, []);

    return <StyledHeaderInput {...props} ref={textareaEl} onInput={handleInput} onKeyDown={handleEnter} />;
};

const BricksHeader = ({ uuid, renameFile, deleteFile, defaultValue, onShowNavigation }) => {
    const [filename, setFilename] = useState(defaultValue || '');

    useEffect(
        () =>
            subscribe(change => {
                change.uuid === uuid && setFilename(change.name);
            }),
        [uuid]
    );

    const handleShare = useCallback(() => share(), []);

    const updateFileName = useCallback(
        throttle(({ name, uuid, renameFile }) => {
            renameFile({ name, uuid });
        }, 150),
        []
    );

    const handleValueChange = useCallback(
        ({ target }) => {
            const { value } = target;
            setFilename(value);
            updateFileName({ name: value, uuid, renameFile });
        },
        [updateFileName, renameFile, uuid]
    );

    const handleDeleteFile = useCallback(() => {
        showSnack('Are you sure?', { actions: ['ok', 'dismiss'] }).then(result => {
            result === 'ok' && deleteFile({ uuid });
        });
    }, [uuid, deleteFile]);

    return (
        <StyledBricksHeader>
            <GoToNavigationWrapper>
                <GoToNavigation onClick={onShowNavigation}>
                    <ChevronLeft />
                    <span>Notes</span>
                </GoToNavigation>
                <StyledToolbar mobile>
                    <IconButton title="Share" onClick={handleShare}>
                        <ShareIcon />
                    </IconButton>
                    <FileMenuButton onDelete={handleDeleteFile} />
                </StyledToolbar>
            </GoToNavigationWrapper>
            <TextAreaWrapper>
                <AutoHeightTextArea
                    value={filename}
                    onChange={handleValueChange}
                    aria-label="Note title"
                    placeholder="Type note title..."
                />
            </TextAreaWrapper>
            <StyledToolbar>
                <IconButton title="Share" onClick={handleShare}>
                    <ShareIcon />
                </IconButton>
                <FileMenuButton onDelete={handleDeleteFile} />
            </StyledToolbar>
        </StyledBricksHeader>
    );
};

export default BricksHeader;
