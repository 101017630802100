import React, { memo } from 'react';

const Logo = memo(props => {
    return (
        <svg viewBox="0 0 515 515" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M256.5 1L479.5 130L257.5 259L36.5 130L256.5 1Z" fill="#FFC2A8" />
            <path d="M257.5 259L256.5 514L479.5 386V130L257.5 259Z" fill="#9697F6" />
            <path d="M36.5 130L257.5 259L256.5 514L38.5 386L36.5 130Z" fill="#AAC3FF" />
            <path
                d="M257 1.15991L37 130.13V385.89L257 513.82L479 385.91V130.11L257 1.15991ZM257 20.8399L452.91 134.61L257 248.66L62.16 135.08L257 20.8399ZM54 150L249.47 264L248.56 489.31L54 376.11V150ZM265.56 489.26L266.47 262.82L462 149V376.1L265.56 489.26Z"
                fill="black"
            />
            <path
                d="M293.92 159.09C296.536 162.797 297.959 167.213 298 171.75C298 187.63 280.09 200.5 258 200.5C235.91 200.5 218 187.63 218 171.75C218.041 167.213 219.464 162.797 222.08 159.09C214.23 165.2 209.5 173.38 209.5 182.5C209.5 201.85 230.8 217 258 217C285.2 217 306.5 201.85 306.5 182.5C306.5 173.38 301.77 165.2 293.92 159.09Z"
                fill="black"
            />
            <path
                d="M292.92 61.0901C295.536 64.7971 296.959 69.2134 297 73.7501C297 89.6301 279.09 102.5 257 102.5C234.91 102.5 217 89.6301 217 73.7501C217.041 69.2134 218.464 64.7971 221.08 61.0901C213.23 67.2001 208.5 75.3801 208.5 84.5001C208.5 103.85 229.8 119 257 119C284.2 119 305.5 103.85 305.5 84.5001C305.5 75.3801 300.77 67.2001 292.92 61.0901Z"
                fill="black"
            />
            <path
                d="M385.85 108.09C389.695 112.114 391.89 117.435 392 123C392 138.46 374.09 151 352 151C329.91 151 312 138.46 312 123C312.11 117.435 314.305 112.114 318.15 108.09C309.07 114.33 303.5 123.09 303.5 133C303.5 152.35 324.8 167.5 352 167.5C379.2 167.5 400.5 152.35 400.5 133C400.5 123.11 394.93 114.33 385.85 108.09Z"
                fill="black"
            />
            <path
                d="M191.85 108.09C195.695 112.114 197.89 117.435 198 123C198 138.46 180.09 151 158 151C135.91 151 118 138.46 118 123C118.11 117.435 120.305 112.114 124.15 108.09C115.07 114.33 109.5 123.09 109.5 133C109.5 152.35 130.8 167.5 158 167.5C185.2 167.5 206.5 152.35 206.5 133C206.5 123.11 200.93 114.33 191.85 108.09Z"
                fill="black"
            />
        </svg>
    );
});

export default Logo;
