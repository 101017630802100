import { Plugin, PluginKey } from 'prosemirror-state';

const key = new PluginKey('changes');

export const createChangesPlugin = ({ uuid, onChange }) => {
    return new Plugin({
        key,
        state: {
            init() {
                return false;
            },
            apply(tr) {
                if (tr.docChanged) {
                    onChange({ uuid, doc: tr.doc.toJSON() });
                }
                return false;
            }
        }
    });
};
