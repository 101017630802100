export const opacityAnimation = props => {
    switch (props.transition) {
        case 'entering':
            return `
                opacity: 0;
            `;
        case 'entered':
            return `
                opacity: 1;
            `;
        case 'exiting':
            return `
                opacity: 0;
            `;
        default:
    }
};
