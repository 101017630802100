import styled from 'styled-components/macro';
import IconButton from 'components/svg-icons/IconButton';

export const StyledFileTree = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin: 0;
    padding: 0;
    overflow-y: auto;

    color: rgba(0, 0, 0, 0.6);
    font-size: var(--nav-font-size);
    line-height: 1.4;

    &::-webkit-scrollbar {
        width: 0.5rem;

        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border: 2px solid rgba(0, 0, 0, 0);

        border-radius: 0.5rem;

        background-color: rgba(196, 196, 196, 0.5);
        background-clip: padding-box;
    }

    &::-webkit-scrollbar-button {
        display: none;
        width: 0;
        height: 0;
    }

    &::-webkit-scrollbar-corner {
        background-color: transparent;
    }
`;

export const FileHeader = styled.li`
    padding: 4px 16px 4px 40px;

    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;

    list-style: none;

    background-color: ${({ selected }) => (selected ? '#fefefe' : 'transparent')};
    outline: 0;

    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    &:hover {
        background-color: #fefefe;
    }
`;

export const FolderHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
    padding: 4px 10px 4px 20px;

    color: ${({ selected }) => (selected ? 'rgba(0, 0, 0, 0.8)' : 'inherit')};

    text-overflow: ellipsis;
`;

export const FolderLabel = styled.div`
    display: inline-block;
    flex: 1 1 auto;
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;

    cursor: pointer;

    user-select: none;
`;

export const FolderControls = styled.div`
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    opacity: 0;

    transition: opacity 300ms;

    ${FolderHeader}:hover > & {
        opacity: 1;
    }

    & > * {
        margin-left: 8px;
    }
`;

const collapseAnimation = props => {
    switch (props.transition) {
        case 'entering':
            return `
                max-height: 0;
            `;
        case 'entered':
            return `
                max-height: ${props.maxHeight}px;
            `;
        case 'exiting':
            return `
                max-height: 0;
            `;
        default:
    }
};

export const FolderFiles = styled.ul`
    margin: 0;
    padding: 0;
    overflow: hidden;

    transition: max-height 0.3s ease-in-out;
    ${collapseAnimation}
`;

export const ExpandButton = styled(IconButton)`
    font-size: var(--nav-expand-btn-size);

    transform: ${({ opened }) => (opened ? 'rotate(180deg)' : 'rotate(0deg)')};

    transition: transform 0.3s ease-in-out;
`;

export const AddButton = styled.button`
    box-sizing: border-box;
    padding: 4px 6px;

    border: none;

    color: rgba(0, 0, 0, 0.4);
    font-size: var(--nav-font-size);

    background: transparent;
    outline: none;

    cursor: pointer;
`;

export const AddButtonWrapper = styled.li`
    margin: 0 0 0 34px;
    padding: 0;

    list-style: none;
    outline: none;
`;
