/**
 * @param {String}  str  number string literal
 * @return Number parsed number
 */
export const parseNumber = str => {
    if (typeof str === 'number') return str;
    let literal = str.split(' ').join('');
    let multiplier = 1;

    if (literal.endsWith('k') || literal.endsWith('K')) {
        multiplier = 1000;
        literal.slice(0, literal.length - 1);
    }

    if (literal.endsWith('m') || literal.endsWith('M')) {
        multiplier = 1000000;
        literal.slice(0, literal.length - 1);
    }

    literal = literal.replace(',', '.');
    return parseFloat(literal) * multiplier;
};

export const precise = (n, precision = 4) => {
    return parseNumber(parseFloat(n).toPrecision(precision));
};
