import { MathLexer } from 'math/lexer';
import { parser } from 'math/parser';
import { createNumber } from './calculate/number-type';
import { createPercent } from './calculate/percent-type';
import { symbolToOperator } from 'math/calculate/operators';
import { calculate } from 'math/calculate/calculate';

const BaseCstVisitor = parser.getBaseCstVisitorConstructor();

class MathInterpreter extends BaseCstVisitor {
    constructor() {
        super();
        // This helper will detect any missing or redundant methods on this visitor
        this.validateVisitor();
    }

    expression(ctx) {
        if (ctx.hasOwnProperty('percentOfExpression')) {
            return this.visit(ctx.percentOfExpression);
        }
        if (ctx.hasOwnProperty('additionExpression')) {
            return this.visit(ctx.additionExpression);
        }
        if (ctx.hasOwnProperty('whatNumberOfPercentExpression')) {
            return this.visit(ctx.whatNumberOfPercentExpression);
        }
    }

    additionExpression(ctx) {
        let result = this.visit(ctx.lhs);

        // "rhs" key may be undefined as the grammar defines it as optional (MANY === zero or more).
        if (ctx.rhs) {
            ctx.rhs.forEach((rhsOperand, idx) => {
                // there will be one operator for each rhs operand
                let rhsValue = this.visit(rhsOperand);
                let operator = ctx.AdditionOperator[idx];

                result = calculate(result, rhsValue, symbolToOperator[operator.image]);
            });
        }
        return result;
    }

    multiplicationExpression(ctx) {
        let result = this.visit(ctx.lhs);

        // "rhs" key may be undefined as the grammar defines it as optional (MANY === zero or more).
        if (ctx.rhs) {
            ctx.rhs.forEach((rhsOperand, idx) => {
                // there will be one operator for each rhs operand
                let rhsValue = this.visit(rhsOperand);
                let operator = ctx.MultiplicationOperator[idx];

                result = calculate(result, rhsValue, symbolToOperator[operator.image]);
            });
        }

        return result;
    }

    atomicExpression(ctx) {
        if (ctx.parenthesisExpression) {
            // passing an array to "this.visit" is equivalent
            // to passing the array's first element
            return this.visit(ctx.parenthesisExpression);
        } else if (ctx.unaryExpression) {
            return this.visit(ctx.unaryExpression);
        } else if (ctx.NumberLiteral) {
            return createNumber(ctx.NumberLiteral[0].image);
        } else if (ctx.PercentLiteral) {
            let val = ctx.PercentLiteral[0].image;
            return createPercent(val);
        }
    }

    parenthesisExpression(ctx) {
        // The ctx will also contain the parenthesis tokens, but we don't care about those
        // in the context of calculating the result.
        return this.visit(ctx.expression);
    }

    unaryExpression(ctx) {
        return this.visit(ctx.atomicExpression).negate();
    }

    powerExpression(ctx) {
        let base = this.visit(ctx.base);
        if (!ctx.exponent) return base;
        const arr = ctx.exponent;

        let reduced = arr.reduceRight((acc, cur) => {
            const val = this.visit(cur);
            return calculate(val, acc, symbolToOperator['^']);
        }, createNumber(1));

        return calculate(base, reduced, symbolToOperator['^']);
    }

    whatNumberOfPercentExpression(ctx) {
        const percent = createPercent(ctx.PercentLiteral[0].image);
        const number = createNumber(ctx.NumberLiteral[0].image);
        return percent.risof(number);
    }

    percentOfExpression(ctx) {
        const percent = createPercent(ctx.PercentLiteral[0].image);
        const number = createNumber(ctx.NumberLiteral[0].image);
        return percent.of(number);
    }
}

export const evaluate = expr => {
    const interpreter = new MathInterpreter();
    const lexResult = MathLexer.tokenize(expr);
    parser.input = lexResult.tokens;

    const expression = parser.expression();
    const result = interpreter.visit(expression);

    return {
        result: result,
        lexerErrors: lexResult.errors,
        parserErrors: parser.errors
    };
};
