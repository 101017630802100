import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Notepad from 'components/notepad/Notepad';
import FilesContext from 'components/files-context/FilesContext';
import throttle from 'lodash/throttle';
import * as BricksDb from 'db/db';
import * as dbChangeTypes from 'constants/db-change-types';
import { uniqueTabId } from 'db/db-change-hook';
import StyledNotepad from 'components/notepad/StyledNotepad';
import { actionTypes, subscribe } from 'emitters/app-emitter';
import { copyToClipboard } from 'utils/clipboard';
import { buildShareUrl } from 'utils/hash-utils';
import { showSnack } from 'emitters/snack-emitter';

const StatefulNotepad = ({ name }) => {
    const [fileContent, setFileContent] = useState({});
    const { selectedFile } = useContext(FilesContext);
    const { uuid } = selectedFile || {};
    const uuidRef = useRef(uuid);

    const dbRef = useRef();
    const contentRef = useRef();

    useEffect(() => {
        dbRef.current = BricksDb.get();
    }, []);

    useEffect(() => {
        uuidRef.current = uuid;
    }, [uuid]);

    useEffect(() => {
        dbRef.current.on('changes', changes => {
            changes.forEach(change => {
                const changesMadeByCurrentTab =
                    change.source === uniqueTabId || (change.obj && change.obj.source === uniqueTabId);
                const fileContentsTable = change.table === 'fileContents';

                if (changesMadeByCurrentTab || !fileContentsTable) return;

                switch (change.type) {
                    case dbChangeTypes.CREATED: {
                        const { uuid } = change.obj;
                        if (uuid === uuidRef.current) {
                            setFileContent(change.obj);
                        }
                        break;
                    }
                    case dbChangeTypes.UPDATED: {
                        if (change.key === uuidRef.current) {
                            setFileContent(change.obj);
                        }
                        break;
                    }
                    default:
                }
            });
        });
    }, []);

    useEffect(() => {
        if (!uuid || fileContent.uuid === uuid) return;

        dbRef.current.fileContents.get(uuid).then(found => {
            setFileContent(found ? found : { uuid });
        });
    }, [uuid, fileContent]);

    useEffect(() => {
        contentRef.current = fileContent.content;
    });

    useEffect(() => {
        return subscribe(({ type }) => {
            switch (type) {
                case actionTypes.SHARE: {
                    const content = contentRef.current;
                    if (!content || !content.doc) {
                        showSnack('Note is empty', { timeout: 1000 });
                        return;
                    }
                    copyToClipboard(buildShareUrl({ doc: content.doc, title: name }));
                    showSnack('Url copied', { timeout: 1000 });
                    return;
                }
                default:
                    return;
            }
        });
    }, [name]);

    const updateFileContent = useCallback(
        throttle(({ updater, uuid, doc }) => {
            updater({ uuid, doc });
        }, 150),
        []
    );

    const handleChange = useCallback(
        ({ uuid, doc }) => {
            if (!uuid) return;
            contentRef.current = { doc };
            const updater = ({ uuid, doc }) => dbRef.current.fileContents.put({ uuid, content: { doc } });
            updateFileContent({ updater, uuid, doc });
        },
        [updateFileContent]
    );

    const initialized = uuid && uuid === fileContent.uuid;

    return initialized ? (
        <Notepad uuid={uuid} initialContent={fileContent.content} onChange={handleChange} />
    ) : (
        <StyledNotepad />
    );
};

export default StatefulNotepad;
