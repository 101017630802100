import styled from 'styled-components/macro';
import { opacityAnimation } from 'utils/animations/opacity-animation';
import { FILE_MENU_ZI } from 'constants/z-indexes';

export const StyledFileMenu = styled.div`
    position: fixed;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.25rem 0;

    border-radius: 0.25rem;

    background-color: white;
    box-shadow: var(--material-box-shadow);
    opacity: 0;

    transition: opacity 0.3s ease-in-out;
    ${opacityAnimation}
`;

export const FileMenuOverlay = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: ${FILE_MENU_ZI};

    background: transparent;
`;

export const MenuItem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.25rem 1rem 0.25rem 0.5rem;

    cursor: pointer;

    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }
`;

export const MenuIcon = styled.div`
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    font-size: 16px;
`;

export const MenuLabel = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 1rem;

    color: rgba(0, 0, 0, 0.4);
    font-size: var(--nav-font-size);
`;
