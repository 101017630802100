import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
    TextButton,
    NavigationHeader,
    NavigationContainer,
    NavigationFooter
} from 'components/navigation/StyledNavigation';
import FileTree from 'components/file-tree/FileTree';
import FilesContext from 'components/files-context/FilesContext';
import * as visibilityTypes from 'components/navigation/navigation-visibility-types';
import Logo from 'components/logo/Logo';

const Navigation = ({ visibilitySubscriber }) => {
    const [visibility, setVisibility] = useState(visibilityTypes.NONE);
    const { fileTree, addFolder, addFileAndSelect, selectFile, deleteFolder, selectedFile } = useContext(FilesContext);

    useEffect(() => {
        if (visibility === visibilityTypes.HIDDEN) {
            const resetVisibility = () =>
                setVisibility(prev => (prev === visibilityTypes.HIDDEN ? visibilityTypes.NONE : prev));
            setTimeout(resetVisibility, 300);
        }
    }, [visibility]);

    useEffect(() => {
        if (visibilitySubscriber) {
            return visibilitySubscriber(visibility => setVisibility(visibility));
        }
    }, [visibilitySubscriber]);

    const selectFileAndHide = useCallback(
        (...args) => {
            selectFile(...args);
            setVisibility(prev => (prev === visibilityTypes.VISIBLE ? visibilityTypes.HIDDEN : prev));
        },
        [selectFile]
    );

    const addFileAndSelectAndHide = useCallback(
        (...args) => {
            addFileAndSelect(...args);
            setVisibility(prev => (prev === visibilityTypes.VISIBLE ? visibilityTypes.HIDDEN : prev));
        },
        [addFileAndSelect]
    );

    const fileTreeProps = {
        fileTree,
        addFileAndSelect: addFileAndSelectAndHide,
        selectFile: selectFileAndHide,
        deleteFolder,
        selectedFile
    };

    return (
        <NavigationContainer visibility={visibility}>
            <NavigationHeader>
                <Logo />
                <span>Bricks</span>
            </NavigationHeader>
            <FileTree {...fileTreeProps} />
            <NavigationFooter>
                <TextButton onClick={addFolder}>New Folder</TextButton>
            </NavigationFooter>
        </NavigationContainer>
    );
};

export default Navigation;
