import Dexie from 'dexie';
import 'dexie-observable';
import { addSourceToTransaction } from 'db/db-change-hook';
import * as folderTypes from 'constants/folder-types';

const tables = ['files', 'folders', 'fileContents'];

const create = () => {
    const db = new Dexie('bricks');

    db.version(1).stores({
        folders: '$$uuid, createdAt, type',
        files: '$$uuid, folderId, createdAt',
        fileContents: 'uuid, modifiedAt'
    });

    db.on('populate', async () => {
        const folderId = await db.folders.add({ name: 'Notes', createdAt: Date.now(), type: folderTypes.DEFAULT });
        db.files.add({ folderId, createdAt: Date.now() });
        db.folders.add({ name: 'Shared With Me', createdAt: Date.now(), type: folderTypes.SHARED_WITH_ME });
    });

    tables.forEach(table => addSourceToTransaction(db[table]));

    db.open();

    return db;
};

let dbInstance = null;

export const get = () => {
    if (dbInstance === null) {
        dbInstance = create();
    }
    return dbInstance;
};
