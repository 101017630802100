/**
 * Passing text to the clipboard
 *
 * @param {string} text Text that needed to be copied
 */
export const copyToClipboard = text => {
    const el = document.createElement('textarea');
    el.value = text;
    el.style.all = 'unset';
    el.style.position = 'fixed';
    el.style.top = '0';
    el.style.height = '1px';
    el.style.width = '1px';
    el.style.clip = 'rect(0, 0, 0, 0)';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
};
