import React, { useCallback, useRef, useState } from 'react';
import MoreVert from 'components/svg-icons/MoreVert';
import IconButton from 'components/svg-icons/IconButton';
import FileMenu from 'components/file-menu/FileMenu';

const FileMenuButton = ({ onDelete }) => {
    const popoverAnchor = useRef();
    const [opened, setOpened] = useState(false);

    const toggle = useCallback(() => {
        setOpened(prev => !prev);
    }, []);

    const hide = useCallback(() => {
        setOpened(false);
    }, []);

    return (
        <>
            <IconButton ref={popoverAnchor} title="Open menu" onClick={toggle}>
                <MoreVert />
            </IconButton>
            <FileMenu opened={opened} nodeRef={popoverAnchor} onClose={hide} onDelete={onDelete} />
        </>
    );
};

export default FileMenuButton;
