import React, { useCallback, useContext, useRef } from 'react';
import StyledBricks, { Gutter, NotepadContainer } from 'components/bricks/StyledBricks';
import BricksHeader from 'components/bricks-header/BricksHeader';
import { focus } from 'emitters/app-emitter';
import FilesContext from 'components/files-context/FilesContext';
import StatefulNotepad from 'components/notepad/StatefulNotepad';
import { useSharedNotesReader } from 'components/bricks/shared-notes-hook';

const Bricks = ({ onShowNavigation }) => {
    const containerEl = useRef();
    const handleFocus = useCallback(({ target }) => target === containerEl.current && focus(), []);

    const { inited, addSharedFileAndSelect, selectedFile, renameFile, deleteFile } = useContext(FilesContext);
    const { uuid, name } = selectedFile || {};

    const headerProps = { onShowNavigation, renameFile, deleteFile };

    useSharedNotesReader(inited, addSharedFileAndSelect);

    return (
        <StyledBricks ref={containerEl} onClick={handleFocus}>
            <Gutter />
            <BricksHeader key={uuid} uuid={uuid} defaultValue={name} {...headerProps} />
            <NotepadContainer>
                <StatefulNotepad name={name} />
            </NotepadContainer>
        </StyledBricks>
    );
};

export default Bricks;
