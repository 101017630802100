import { inputRules, InputRule } from 'prosemirror-inputrules';
import { schema } from 'components/notepad/schema';

const parseLineExpression = str => {
    let frontOperation = null;
    let backOperation = null;

    if (/^[-+/*,]/.test(str)) {
        frontOperation = str[0];
    }

    if (/[-+/*,]$/.test(str)) {
        backOperation = str[str.length - 1];
    }

    const normalized = /^l/i.test(str) ? str.slice(1, str.length - 1) : str.slice(2, str.length - 1);

    return { lineNumber: parseInt(normalized), frontOperation, backOperation };
};

const lineInputRule = new InputRule(/(^|[\s-+/*,])l[1-9]\d*[\s-+/*,]/gi, (state, match, start, end) => {
    const lineNumberToId = {};

    let index = 0;
    state.doc.descendants((node, pos) => {
        if (node.type !== schema.nodes.paragraph) return;
        index++;
        const { dataId } = node.attrs;
        lineNumberToId[index] = { pos, dataId };
    });

    const { lineNumber, frontOperation, backOperation } = parseLineExpression(match[0]);
    const { pos, dataId } = lineNumberToId[lineNumber] || {};
    if (!dataId || pos > start) return null;

    let nextTr = state.tr.delete(start, end);

    if (frontOperation !== null) nextTr = nextTr.insertText(frontOperation);
    nextTr = nextTr.replaceSelectionWith(schema.nodes.expression.create({ dataId }));
    if (backOperation !== null) nextTr = nextTr.insertText(backOperation);

    return nextTr;
});

export const lineInputRulePlugin = inputRules({ rules: [lineInputRule] });
