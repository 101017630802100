import keyBy from 'lodash/keyBy';

export const operators = [
    {
        symbol: '+',
        name: 'plus'
    },
    {
        symbol: '-',
        name: 'minus'
    },
    {
        symbol: '*',
        name: 'multiply'
    },
    {
        symbol: '/',
        name: 'divide'
    },
    {
        symbol: '^',
        name: 'power'
    }
];

export const symbolToOperator = keyBy(operators, op => op.symbol);
