import { schema } from 'components/notepad/schema';
import { Decoration, DecorationSet } from 'prosemirror-view';
import { Plugin } from 'prosemirror-state';

function calculateParagraphsPosition(doc) {
    const paragraphs = [];

    doc.descendants((node, pos) => {
        if (node.type !== schema.nodes.paragraph) return;
        paragraphs.push({ pos });
    });

    return paragraphs;
}

function addLineNumbers(doc) {
    const decos = [];
    const paragraphs = calculateParagraphsPosition(doc);
    paragraphs.forEach(({ pos }, index) => {
        decos.push(Decoration.widget(pos, createLineNumber(index + 1)));
    });
    return DecorationSet.create(doc, decos);
}

function createLineNumber(number) {
    const lineNumber = document.createElement('div');
    lineNumber.className = 'line-number';
    lineNumber.textContent = number;
    return lineNumber;
}

const lineNumberPlugin = new Plugin({
    state: {
        init(_, { doc }) {
            return addLineNumbers(doc);
        },
        apply(tr, old) {
            return tr.docChanged ? addLineNumbers(tr.doc) : old;
        }
    },
    props: {
        decorations(state) {
            return this.getState(state);
        }
    }
});

export default lineNumberPlugin;
