import styled from 'styled-components/macro';
import { sm } from 'constants/device-widths';
import { NAVIGATION_ZI } from 'constants/z-indexes';
import * as vt from 'components/navigation/navigation-visibility-types';

// noinspection ALL
export const NavigationContainer = styled.nav`
    left: 0;

    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    box-sizing: border-box;
    width: 220px;

    @media (max-width: ${sm}px) {
        position: fixed;
        top: 0;
        left: ${({ visibility: v }) => (v === vt.VISIBLE ? '0' : '-100vw')};
        z-index: ${NAVIGATION_ZI};

        width: 100vw;
        height: 100vh;

        background-color: var(--main-bg-color);

        transition: ${({ visibility: v }) => (v === vt.NONE ? 'none' : 'left 0.3s ease-in-out')};
    }

    /* stylelint-disable*/
    @supports (-webkit-touch-callout: none) {
        @media (max-width: ${sm}px) {
            height: -webkit-fill-available;
        }
    }
`;

export const TextButton = styled.button`
    padding: 0.375rem;

    border: none;

    color: var(--nav-color);
    font-size: var(--nav-font-size);

    background: transparent;

    cursor: pointer;
`;

export const NavigationFooter = styled.div`
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.25rem;
`;

export const NavigationHeader = styled.div`
    flex: 0 0 auto;
    margin-bottom: 0.725rem;
    padding: 1rem 1.25rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    color: var(--nav-color);
    font-weight: 500;
    font-size: var(--nav-title-font-size);

    & > svg {
        height: 2em;
        width: 2em;
    }

    & > span {
        margin-left: 1rem;
    }
`;

export const Splitter = styled.div`
    flex: 0 0 auto;
    height: 0.25rem;
    margin-bottom: 0.25rem;

    border-bottom: solid 1px var(--splitter-border-color);
`;
