import React, { useRef } from 'react';
import { Transition } from 'react-transition-group';
import { AddButton, AddButtonWrapper, FileHeader, FolderFiles } from 'components/file-tree/StyledFileTree';

const timeouts = { enter: 0, exit: 300 };
const rowHeightPx = 28;

const TransitionedFolderFiles = ({ folder, selectedFileId, onAddFile, onSelectFile, ...restProps }) => {
    const nodeRef = useRef();
    return (
        <Transition unmountOnExit timeout={timeouts} nodeRef={nodeRef} {...restProps}>
            {transition => (
                /* FolderFiles has max-height property which affects flex-shrink, use div wrapper to avoid it */
                <div>
                    <FolderFiles
                        ref={nodeRef}
                        transition={transition}
                        maxHeight={rowHeightPx * (folder.files.length + 1)}
                    >
                        <AddButtonWrapper>
                            <AddButton title="Add note" onClick={onAddFile}>
                                Add note...
                            </AddButton>
                        </AddButtonWrapper>
                        {folder.files.map(file => (
                            <FileHeader
                                key={file.uuid}
                                selected={selectedFileId === file.uuid}
                                onClick={() => onSelectFile({ uuid: file.uuid })}
                            >
                                {file.name || 'Untitled'}
                            </FileHeader>
                        ))}
                    </FolderFiles>
                </div>
            )}
        </Transition>
    );
};

export default TransitionedFolderFiles;
