import { Schema } from 'prosemirror-model';

// :: Object
const nodes = {
    // :: NodeSpec The top level document node.
    doc: {
        content: 'block+'
    },
    paragraph: {
        attrs: {
            dataId: { default: '' }
        },
        draggable: false,
        content: 'inline*',
        group: 'block',
        parseDOM: [
            {
                tag: 'p[data-id]',
                getAttrs: dom => {
                    return {
                        dataId: dom.getAttribute('data-id')
                    };
                }
            }
        ],
        toDOM: node => {
            const { attrs } = node;
            const { dataId } = attrs;
            return ['p', { 'data-id': dataId }, 0]; //
        }
    },
    expression: {
        attrs: { dataId: {} },
        inline: true,
        group: 'inline',
        draggable: false,
        toDOM: node => [
            'div',
            {
                'data-id': node.attrs.dataId,
                class: 'expression'
            }
        ],
        parseDOM: [
            {
                tag: 'div[data-id]',
                getAttrs: dom => {
                    return {
                        dataId: dom.getAttribute('data-id')
                    };
                }
            }
        ]
    },
    text: {
        draggable: false,
        group: 'inline'
    }
};

const emDOM = ['em', 0];
const strongDOM = ['strong', 0];
const codeDOM = ['code', 0];

const marks = {
    em: {
        parseDOM: [{ tag: 'i' }, { tag: 'em' }, { style: 'font-style=italic' }],
        toDOM() {
            return emDOM;
        }
    },
    // also match `<b>` and `font-weight: bold`.
    strong: {
        parseDOM: [
            { tag: 'strong' },
            // This works around a Google Docs misbehavior where
            // pasted content will be inexplicably wrapped in `<b>`
            // tags with a font-weight normal.
            { tag: 'b', getAttrs: node => node.style.fontWeight !== 'normal' && null },
            { style: 'font-weight', getAttrs: value => /^(bold(er)?|[5-9]\d{2,})$/.test(value) && null }
        ],
        toDOM() {
            return strongDOM;
        }
    },
    // :: MarkSpec A strong mark. Rendered as `<strong>`, parse rules
    code: {
        parseDOM: [{ tag: 'code' }],
        toDOM: function toDOM() {
            return codeDOM;
        }
    }
};

const schema = new Schema({ nodes: nodes, marks: marks });

export { marks, nodes, schema };
