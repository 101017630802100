import React, { useCallback, useRef } from 'react';
import { FileMenuOverlay, MenuIcon, MenuItem, MenuLabel, StyledFileMenu } from 'components/file-menu/StyledFileMenu';
import DeleteIcon from 'components/svg-icons/DeleteIcon';

const FileMenuContent = React.forwardRef((props, ref) => {
    const overlayEl = useRef();

    const { onClose } = props;
    const handleOverlayClick = useCallback(({ target }) => target === overlayEl.current && onClose(), [onClose]);

    const { transition, onDelete } = props;

    const handleDelete = useCallback(() => {
        onDelete();
        onClose();
    }, [onDelete, onClose]);

    return (
        <FileMenuOverlay ref={overlayEl} onClick={handleOverlayClick}>
            <StyledFileMenu ref={ref} transition={transition}>
                <MenuItem onClick={handleDelete}>
                    <MenuIcon>
                        <DeleteIcon />
                    </MenuIcon>
                    <MenuLabel>Delete</MenuLabel>
                </MenuItem>
            </StyledFileMenu>
        </FileMenuOverlay>
    );
});

export default FileMenuContent;
