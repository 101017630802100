import { parseNumber } from 'math/number-literal-utils';
import { NUMBER_TYPE } from 'math/calculate/calculation-types';
import { calcTypeExp } from './type-utils';

export class NumberType {
    constructor(value) {
        this.type = NUMBER_TYPE;
        this.value = value;
    }

    plus(other) {
        return calcTypeExp((l, r) => new NumberType(l + r)).onlyIfOtherIsNumber(this, other);
    }

    minus(other) {
        return calcTypeExp((l, r) => new NumberType(l - r)).onlyIfOtherIsNumber(this, other);
    }

    multiply(other) {
        return calcTypeExp((l, r) => new NumberType(l * r)).onlyIfOtherIsNumber(this, other);
    }

    divide(other) {
        return calcTypeExp((l, r) => new NumberType(l / r)).onlyIfOtherIsNumber(this, other);
    }

    power(other) {
        return calcTypeExp((l, r) => new NumberType(l ** r)).onlyIfOtherIsNumber(this, other);
    }

    negate() {
        return new NumberType(-this.value);
    }
    toString() {
        return this.value.toString();
    }
}

export const createNumber = val => new NumberType(parseNumber(val));
