import { schema } from 'components/notepad/schema';
import { Decoration, DecorationSet } from 'prosemirror-view';
import { Plugin } from 'prosemirror-state';
import splitLabel from 'components/notepad/plugins/calculation/split-label';
import { evaluate } from 'math/interpreter';

function preEval(doc) {
    const evaled = {};
    const expressions = [];

    const dataIdToLineNumber = {};

    let lineNumber = 0;
    doc.descendants(node => {
        if (node.type !== schema.nodes.paragraph) return;
        lineNumber++;
        const { dataId } = node.attrs;
        dataIdToLineNumber[dataId] = lineNumber;
    });

    // For each node in the document
    doc.descendants((node, pos) => {
        if (node.type !== schema.nodes.paragraph) return;

        const endPos = pos + node.content.size + 2;

        let text = '';
        node.descendants((node, nodePos) => {
            if (node.isText) {
                text += node.text;
            } else if (node.type === schema.nodes.expression) {
                const { dataId } = node.attrs;
                const { value, label } = evaled[dataId] || {};
                const textValue = value === undefined ? '##' : value;
                text += textValue;
                expressions.push({ value: ` ${textValue} `, dataId, position: pos + nodePos + 1, label });
            }
        });

        try {
            const [exp, label] = splitLabel(text);
            // eslint-disable-next-line no-eval
            const { result } = evaluate(exp);
            if (result !== undefined) {
                evaled[node.attrs.dataId] = { value: result.toString(), position: endPos, label };
            }
        } catch (e) {}
    });

    return { evaled, expressions, dataIdToLineNumber };
}

function evalDecorator(doc) {
    const decos = [];
    const { evaled, expressions, dataIdToLineNumber } = preEval(doc);
    Object.keys(evaled).forEach(dataId => {
        const { value, position } = evaled[dataId];
        decos.push(Decoration.widget(position, valueBadge({ value, dataId, position }), { ignoreSelection: true }));
    });
    expressions.forEach(({ dataId, position, value, label }) => {
        const lineNumber = dataIdToLineNumber[dataId];
        decos.push(Decoration.widget(position, expressionBadge({ value, label, dataId, position, lineNumber })));
    });
    return DecorationSet.create(doc, decos);
}

function valueBadge({ value, dataId, position }) {
    const icon = document.createElement('div');
    icon.className = 'value-badge';
    icon.setAttribute('data-id', dataId);
    icon.meta = { dataId, position };
    icon.innerHTML = value;
    return icon;
}

function expressionBadge({ value, dataId, label, position, lineNumber }) {
    const icon = document.createElement('div');
    icon.className = 'expression-badge';
    icon.setAttribute('data-id', dataId);
    icon.meta = { dataId, position };
    if (label) icon.title = label;
    icon.innerHTML = `<span class="expression-badge-line"> L${lineNumber}</span>${value}`;
    return icon;
}

const calculationPlugin = new Plugin({
    state: {
        init(_, { doc }) {
            return evalDecorator(doc);
        },
        apply(tr, old) {
            return tr.docChanged ? evalDecorator(tr.doc) : old;
        }
    },
    props: {
        decorations(state) {
            return this.getState(state);
        },
        handleClick(view, pos, event) {
            if (/value-badge/.test(event.target.className)) {
                const { dataId, position } = event.target.meta;
                const { dispatch, state } = view;
                const { from } = state.selection;
                if (position < from) {
                    dispatch(state.tr.replaceSelectionWith(schema.nodes.expression.create({ dataId })));
                }
                return true;
            }
        }
    }
});

export default calculationPlugin;
