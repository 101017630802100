import { NUMBER_TYPE, PERCENT_TYPE } from './calculation-types';

export const calcTypeExp = exp => {
    return {
        onlyIfOtherIsNumber(leftOp, rightOp) {
            const { type } = rightOp;
            return type === NUMBER_TYPE && exp(leftOp.value, rightOp.value);
        },
        onlyIfOtherIsPercent(leftOp, rightOp) {
            const { type } = rightOp;
            return type === PERCENT_TYPE && exp(leftOp.value, rightOp.value);
        }
    };
};
