/**
 * Default value, no transitions, navigation visible if has enough space
 */
export const NONE = 'NONE';
/**
 * Navigation hide with transition
 */
export const HIDDEN = 'HIDDEN';
/**
 * Navigation visible
 */
export const VISIBLE = 'VISIBLE';
