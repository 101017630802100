const splitLabel = text => {
    const result = text.match(/^[^:]*:/g) || [];
    if (result.length === 0) {
        return [text, undefined];
    } else {
        const labelWithColon = result[0];
        const labelLength = labelWithColon.length;
        return [text.slice(labelLength), labelWithColon.slice(0, labelLength - 1)];
    }
};

export default splitLabel;
