import createEventEmitter from 'utils/create-event-emitter';

const appEmitter = createEventEmitter();

export const actionTypes = {
    SAVE: 'SAVE',
    SHARE: 'SHARE',
    FOCUS: 'FOCUS'
};

export const subscribe = appEmitter.subscribe;

export const share = () => appEmitter.emit({ type: actionTypes.SHARE });

export const focus = () => appEmitter.emit({ type: actionTypes.FOCUS });
