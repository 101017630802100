import isFunction from 'lodash/isFunction';

const apply = (leftOp, rightOp, operatorName) => {
    if (isFunction(leftOp[operatorName])) {
        return leftOp[operatorName](rightOp);
    } else {
        return null;
    }
};

//used for right han operator
const prefix = 'r';

export const calculate = (leftOp, rightOp, operator) => {
    const leftHandedResult = apply(leftOp, rightOp, operator.name);

    if (leftHandedResult) {
        return leftHandedResult;
    }

    const rightHandedResult = apply(rightOp, leftOp, prefix + operator.name);

    if (rightHandedResult) {
        return rightHandedResult;
    }

    throw Error(`Not implemented operation ${operator.name} for ${leftOp.type} and ${rightOp.type} types`);
};
