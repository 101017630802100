import React, { memo, useCallback, useState } from 'react';
import {
    ExpandButton,
    FolderControls,
    FolderHeader,
    FolderLabel,
    StyledFileTree
} from 'components/file-tree/StyledFileTree';
import { DEFAULT, SHARED_WITH_ME } from 'constants/folder-types';
import { Splitter } from 'components/navigation/StyledNavigation';
import TransitionedFolderFiles from 'components/file-tree/TransitionedFolderFiles';
import ExpandMore from 'components/svg-icons/ExpandMore';
import FileMenuButton from 'components/file-tree/FileMenuButton';
import { showSnack } from 'emitters/snack-emitter';

const Folder = memo(props => {
    const [opened, setOpened] = useState(null);
    const { folder, deletable = false } = props;
    const { addFileAndSelect, selectFile, selectedFile, deleteFolder } = props;

    const { uuid, folderId } = selectedFile || {};
    const folderIsSelected = folderId === folder.uuid;

    const handleAddFile = useCallback(() => {
        addFileAndSelect({ folderId: folder.uuid });
    }, [addFileAndSelect, folder.uuid]);

    const handleDeleteFolder = useCallback(() => {
        showSnack('Are you sure?', { actions: ['ok', 'dismiss'] }).then(result => {
            result === 'ok' && deleteFolder({ uuid: folder.uuid });
        });
    }, [deleteFolder, folder]);

    const toggleFiles = useCallback(() => {
        setOpened(prev => (prev === null ? !folderIsSelected : !prev));
    }, [folderIsSelected]);

    const expanded = opened === null ? folderIsSelected : opened;

    return (
        <>
            <FolderHeader selected={folderIsSelected}>
                <FolderLabel onClick={toggleFiles}>{folder.name}</FolderLabel>
                <FolderControls>
                    {deletable && <FileMenuButton onDelete={handleDeleteFolder} />}
                    <ExpandButton title="Expand more" opened={expanded} onClick={toggleFiles}>
                        <ExpandMore />
                    </ExpandButton>
                </FolderControls>
            </FolderHeader>
            <TransitionedFolderFiles
                in={expanded}
                folder={folder}
                selectedFileId={uuid}
                onAddFile={handleAddFile}
                onSelectFile={selectFile}
            />
        </>
    );
});

const FileTree = props => {
    const { fileTree, addFileAndSelect, selectFile, selectedFile, deleteFolder } = props;
    const fProps = { addFileAndSelect, selectFile, selectedFile, deleteFolder };
    return (
        <StyledFileTree>
            {fileTree
                .filter(folder => folder.type === SHARED_WITH_ME)
                .map(folder => (
                    <Folder key={folder.uuid} folder={folder} {...fProps} />
                ))}
            <Splitter />
            {fileTree
                .filter(folder => folder.type !== SHARED_WITH_ME)
                .map(folder => (
                    <Folder key={folder.uuid} folder={folder} deletable={folder.type !== DEFAULT} {...fProps} />
                ))}
        </StyledFileTree>
    );
};

export default FileTree;
