import React, { memo } from 'react';
import AppLayout from 'layouts/app-layout/AppLayout';
import AppWorkspace from 'layouts/app-workspace/AppWorkspace';
import Navigation from 'components/navigation/Navigation';
import Bricks from 'components/bricks/Bricks';
import FilesProvider from 'components/files-context/FilesProvider';
import { subscribe, show } from 'emitters/navigation-emitter';

const App = memo(() => {
    return (
        <AppLayout>
            <AppWorkspace>
                <FilesProvider>
                    <Navigation visibilitySubscriber={subscribe} />
                    <Bricks onShowNavigation={show} />
                </FilesProvider>
            </AppWorkspace>
        </AppLayout>
    );
});

export default App;
