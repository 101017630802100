import styled from 'styled-components/macro';
import { ellipsis } from 'polished';
import { md, sm } from 'constants/device-widths';

const StyledNotepad = styled.main`
    position: relative;

    box-sizing: border-box;

    width: 600px;
    margin: 0.5rem 0.25rem 0.5rem 0;
    overflow: visible;

    color: var(--editor-main-color);

    background: var(--editor-bg-color);

    .ProseMirror {
        position: relative;

        padding: 0 1rem;

        font-size: 0.875rem;

        line-height: 1.5;
        white-space: pre-wrap;
        word-wrap: break-word;
        overflow-wrap: break-word;
        hyphens: auto;

        outline: none;
        font-variant-ligatures: none;
    }

    .ProseMirror p {
        margin: 0 0 0.5rem 0;

        color: var(--editor-main-color);
    }

    .value-badge {
        position: relative;
        right: inherit;

        display: block;
        width: inherit;
        margin: 0 0 0.5rem 0;
        padding: 0 1rem;

        border-radius: 0.25rem;

        color: var(--editor-badge-color);
        text-align: left;

        background: var(--editor-badge-bg-color);
        cursor: pointer;
    }

    .line-number {
        position: absolute;
        left: -22px;

        width: 18px;
        padding-top: 2px;

        color: var(--editor-line-number-color);

        font-size: var(--editor-line-number-font-size);
        text-align: right;

        ${ellipsis(18)}
    }

    .expression {
        display: none;
    }

    .expression-badge {
        display: inline;
        box-sizing: border-box;
        margin: 0 2px;

        overflow: hidden;

        border: 1px solid var(--editor-exp-border-color);
        border-radius: 2px;

        color: var(--editor-number-color);
    }

    .expression-badge-line {
        display: inline;

        color: var(--editor-line-number-color);
        font-size: 10px;
    }

    .numbers-token {
        color: var(--editor-number-color);
    }

    .operations-token {
        color: rgba(0, 114, 130, 0.8);
    }

    .labels-token {
        color: rgba(0, 0, 0, 0.6);
        font-weight: 600;
    }

    @media (max-width: ${md}px) {
        & {
            width: calc(100% - 6px);

            border-radius: 0;
        }
    }

    @media (max-width: ${sm}px) {
        & {
            width: calc(100% - 6px);

            border-radius: 0;
        }

        .ProseMirror {
            font-size: 1rem;
        }
    }
`;

export default StyledNotepad;
