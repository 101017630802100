import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';
import { nanoid } from 'nanoid';
import { snackEmitter } from 'emitters/snack-emitter';
import { StyledSnackContainer } from 'components/snack-container/StyledSnackBar';
import SnackBar from 'components/snack-container/SnackBar';

const TransitionedSnackBar = ({ snack, onResolve, ...restProps }) => {
    // avoid using deprecated findDOMNode method
    const nodeRef = useRef();
    return (
        <Transition timeout={300} nodeRef={nodeRef} {...restProps}>
            {transition => <SnackBar ref={nodeRef} transition={transition} onResolve={onResolve} {...snack} />}
        </Transition>
    );
};

const SnackContainer = () => {
    const [snacks, setSnacks] = useState([]);

    useEffect(() => {
        return snackEmitter.subscribe(({ message, options, onResult }) => {
            setSnacks(prevSnacks => [...prevSnacks, { id: nanoid(), message, options, onResult }]);
        });
    }, []);

    const handleSnackResolve = useCallback(id => {
        setSnacks(prevSnacks => prevSnacks.filter(snack => snack.id !== id));
    }, []);

    return (
        <StyledSnackContainer>
            <TransitionGroup component={null}>
                {snacks.map(snack => (
                    <TransitionedSnackBar key={snack.id} snack={snack} onResolve={handleSnackResolve} />
                ))}
            </TransitionGroup>
        </StyledSnackContainer>
    );
};

export default SnackContainer;
