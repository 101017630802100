import styled from 'styled-components/macro';
import { md } from 'constants/device-widths';

// noinspection ALL
const AppWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

    background-color: var(--overlay-bg-color);

    @media (max-width: ${md}px) {
        align-items: stretch;

        background-color: transparent;
    }

    /* stylelint-disable */
    @supports (-webkit-touch-callout: none) {
        /* The hack for Safari */
        min-height: -webkit-fill-available;
    }
    /* stylelint-enable */
`;

export default AppWrapper;
