import { Plugin } from 'prosemirror-state';
import { schema } from 'components/notepad/schema';
import { nanoid } from 'nanoid';

const idPlugin = new Plugin({
    appendTransaction(transactions, prevState, nextState) {
        const nextTr = nextState.tr;
        const dataIdToNode = {};

        let applied = false;

        nextState.doc.descendants((node, pos) => {
            if (node.type === schema.nodes.expression) {
                applied = tryDeleteBrokenLinks({ node, pos, nextTr, dataIdToNode }) || applied;
            } else if (node.type === schema.nodes.paragraph) {
                applied = provideUniqueId({ node, pos, nextTr, dataIdToNode }) || applied;
            }
        });

        return applied && nextTr;
    }
});

function provideUniqueId({ node, pos, nextTr, dataIdToNode }) {
    let applied = false;

    const { dataId } = node.attrs;

    if (dataId === '') {
        const nextDataId = nanoid();
        nextTr.setNodeMarkup(pos, node.type, { dataId: nextDataId });
        dataIdToNode[nextDataId] = { node, pos };
        applied = true;
    } else if (dataId in dataIdToNode) {
        const nextDataId = nanoid();
        const collision = dataIdToNode[dataId];
        nextTr.setNodeMarkup(collision.pos, collision.node.type, { dataId: nextDataId });
        dataIdToNode[dataId] = { node, pos };
        dataIdToNode[nextDataId] = collision;
        applied = true;
    } else {
        dataIdToNode[dataId] = { node, pos };
    }

    return applied;
}

function tryDeleteBrokenLinks({ node, pos, nextTr, dataIdToNode }) {
    let applied = false;

    const { dataId } = node.attrs;
    const linkIsBroken = !(dataId in dataIdToNode);

    if (linkIsBroken) {
        applied = true;
        nextTr.delete(pos, pos + 1);
    }

    return applied;
}

export default idPlugin;
