import { Decoration, DecorationSet } from 'prosemirror-view';
import { Plugin } from 'prosemirror-state';

const highlightedTokens = [
    {
        type: 'numbers',
        regex: /\d(( )?\d)*([.,]\d+)?([km])?/gi
    }
];

function findTokens(doc) {
    const tokens = [];

    function record(type, from, to) {
        tokens.push({ type, from, to });
    }

    doc.descendants((node, pos) => {
        if (!node.isText) return;
        highlightedTokens.forEach(({ type, regex }) => {
            let match;
            while ((match = regex.exec(node.text))) {
                record(type, pos + match.index, pos + match.index + match[0].length);
            }
        });
    });

    return tokens;
}

function addTokenDecorations(doc) {
    const decos = [];
    const tokens = findTokens(doc);
    tokens.forEach(token => {
        decos.push(Decoration.inline(token.from, token.to, { class: `${token.type}-token` }));
    });
    return DecorationSet.create(doc, decos);
}

const highlightPlugin = new Plugin({
    state: {
        init(_, { doc }) {
            return addTokenDecorations(doc);
        },
        apply(tr, old) {
            return tr.docChanged ? addTokenDecorations(tr.doc) : old;
        }
    },
    props: {
        decorations(state) {
            return this.getState(state);
        }
    }
});

export default highlightPlugin;
